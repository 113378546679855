import React, { useRef }  from 'react'
import "./contact.css"
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_eu6xbn5', 'template_0figdmp', form.current, 'OUtuX7WqoWQMrmTqn')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };

  return (
    <section id="contact">
      <h2> Contact </h2>

      <div className="container contact__container">
        <form ref={form} onSubmit={sendEmail}>
          <input 
            type="text"
            name="name"
            placeholder="Full Name"
            required
            />

          <input 
            type="email"
            name="email"
            placeholder="Email"
            required
            />

          <textarea 
            name="message"
            rows="7"
            placeholder="Message"
            required
            />

          <button type="submit" className="btn btn-primary"> Send Message</button>
        </form>
      </div>
      </section>
  )
}

export default Contact