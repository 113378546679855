import React from 'react'
import "./header.css"
import CTA from "./CTA"
import HeaderSocials from "./HeaderSocials"
import videoBg from "../../assets/bg-video.mp4"

const Header = () => {
  return (
    <header>
      <div className="overlay"></div>
      <video autoPlay muted loop src={videoBg} />
      <div className="header__container">
        <h5> Welcome! My name is </h5>
        <h1> CHRISTIAN DE CASTRO </h1>
        <h5 className="text-light"> Fullstack Developer </h5>
        <CTA />
        <HeaderSocials />

      </div>
    </header>
  )
}

export default Header