import React from "react";
import "./skills.css";
import { SiJavascript } from "react-icons/si";
import { SiHtml5 } from "react-icons/si";
import { SiCss3 } from "react-icons/si";
import { SiBootstrap } from "react-icons/si";
import { SiReact } from "react-icons/si";
import {SiPostgresql} from "react-icons/si";
import {SiExpress} from "react-icons/si";
import {SiNodedotjs} from "react-icons/si";
import {SiMocha} from "react-icons/si";
import {SiChai} from "react-icons/si";

const Skills = () => {
  return (
    <section id="skills">
      <h2>Skills</h2>

      <div className="container skills__container">
        <div className="skills__frontend">
          <h3>Frontend Development</h3>
          <div className="skills__content">
            <article className="skills__details">
              <SiJavascript className="icons js" />
              <h4>JavaScript</h4>
            </article>
            <article className="skills__details2 ht">
              <SiHtml5 className="icons htm" />
              <h4>HTML</h4>
            </article>
            <article className="skills__details">
              <SiCss3 className="icons cs" />
              <h4>CSS</h4>
            </article>
            <article className="skills__details2">
              <SiBootstrap className="icons boot" />
              <h4>Bootstrap</h4>
            </article>
            <article className="skills__details">
              <SiReact className="icons re" />
              <h4>React</h4>
            </article>
          </div>
        </div>

        <div className="skills__backend">
          <h3>Backend Development</h3>
          <div className="skills__content">
            <article className="skills__details">
              <SiPostgresql className="icons post" />
              <h4>PostgreSQL</h4>
            </article>
            <article className="skills__details2 ex">
              <SiExpress className="icons express" />
              <h4>Express</h4>
            </article>
            <article className="skills__details">
              <SiNodedotjs className="icons node" />
              <h4>NodeJS</h4>
            </article>
            <article className="skills__details2">
              <SiMocha className="icons mocha" />
              <h4>MochaJS</h4>
            </article>
            <article className="skills__details">
              <SiChai className="icons chai" />
              <h4>ChaiJS</h4>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
