import React from 'react'
import "./about.css"
import picture from "../../assets/about-img.png"

const About = () => {
  return (
    <section id="about">
      <h2>About me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={picture} alt="about image" />
          </div>
        </div>

        <div className="about__content">
          <h2> I'm Christian, and I enjoy creating.</h2>
          <p> Ever since I was a child, I have always been interested in technology. Because of this interest, I put the time in to learn many different programs, which led me to work on software engineering, videography, video editing, photography, pixel art, and graphic design.  After learning about web development, I was amazed at the endless possiblities as long as you had an idea and the drive to turn that idea into an actual product, which was extremely exciting and motivating.
          </p>
          <p>I spent the last 5+ years working as a videographer for many different types of clients including DJ's, MC's, athletes, gym owners, retail businesses, wedding couples, and event coordinators.  Working as a videographer has taught me a lot and has been a great experience, but I am now looking forward to applying myself to the world of software development.
          </p>
        </div>
      </div>
      </section>
  )
}

export default About