import React from 'react'
import "./navbar.css"
import {FaHome} from "react-icons/fa"
import {FaUser} from "react-icons/fa"
import {GiBrain} from "react-icons/gi"
import {BsListTask} from "react-icons/bs"
import {AiFillMessage} from "react-icons/ai"
import {useState} from "react"

const Navbar = () => {
  const [active, setActive] = useState("#")
  return (
    <nav>
      <a href="#" onClick={() => setActive("#")} className={active === "#" ? "active" : ""}> <FaHome /> </a>
      <a href="#skills" onClick={() => setActive("#skills")} className={active === "#skills" ? "active" : ""}> <GiBrain /> </a>
      <a href="#projects" onClick={() => setActive("#projects")} className={active === "#projects" ? "active" : ""}> <BsListTask/> </a>
      <a href="#about" onClick={() => setActive("#about")} className={active === "#about" ? "active" : ""}> <FaUser /> </a>
      <a href="#contact" onClick={() => setActive("#contact")} className={active === "#contact" ? "active" : ""}> <AiFillMessage /> </a>
    </nav>
  )
}

export default Navbar;