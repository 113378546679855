import React from 'react'
import Header from "./components/header/Header"
import Navbar from "./components/navbar/Navbar"
import Skills from "./components/skills/Skills"
import Projects from "./components/projects/Projects"
import About from "./components/about/About"
import Contact from "./components/contact/Contact"

const App = () => {
  return (
    <>
        <Header />
        <Navbar />
        <Skills />
        <Projects />
        <About />
        <Contact />
    </>
  )
}

export default App