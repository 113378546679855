import React from 'react'
import "./projects.css"
import restaurantImage from "../../assets/restaurant-img5.png"
import moviesImage from "../../assets/welovemovies-img.png"
import flashcardImage from "../../assets/flashcard-img3.png"

const Projects = () => {
  return (
    <section id="projects">
      <h2> Projects </h2>

      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={restaurantImage} alt="project 1"></img>
          </div>
          <h3>Restaurant Reservation App</h3>
          <hr></hr>
          <h4>
            Application that can be used as a reservation system for restaurants.  Users of this application are the employees of dining establishments to take reservations when customers call.  When customers with a reservation arrive at the restaurant, employees could also seat them at unoccupied tables.
          </h4>
          <div className="technologies">
          <h5>Built with:</h5>
          </div>
          <div className="project-description">
            <ul className="project-skills">
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>ReactJS</li>
              <li>NodeJS</li>
              <li>ExpressJS</li>
              <li>PostgreSQL</li>
              <li>KnexJS</li>
              <li>Bootstrap</li>
            </ul>
          </div>
          <div className="portfolio__item-CTA">
            <a href="https://github.com/christianDCdev/restaurant-reservation" target="__blank" className="btn">Code</a>
            <a href="https://restaurant-reservation-dashboard.vercel.app/dashboard" target="__blank" className="btn btn-primary">Live Demo</a>
          </div>
        </article>

        <article className="portfolio__item">
        <div className="portfolio__item-image">
            <img src={moviesImage} alt="project 2"></img>
          </div>
          <h3>We Love Movies App (API)</h3>
          <hr></hr>
          <h4>API for an application that displays movies, which theaters they are playing at, and critics' reviews of those movies. Provides access to relevant information through route and query parameters.</h4>
          <div className="technologies">
          <h5>Built with:</h5>
          </div>
          <div className="project-description">
            <ul className="project-skills">
              <li>NodeJS</li>
              <li>ExpressJS</li>
              <li>PostgreSQL</li>
              <li>KnexJS</li>
            </ul>
          </div>
          <div className="portfolio__item-CTA">
            <a href="https://github.com/christianDCdev/we-love-movies-backend" target="__blank" className="btn">Code</a>
            <a className="btn btn-secondary">Demo Soon</a>
          </div>
        </article>

        <article className="portfolio__item">
        <div className="portfolio__item-image">
            <img src={flashcardImage} alt="project 3"></img>
          </div>
          <h3>Flashcard App</h3>
          <hr></hr>
          <h4>
            Application that allows users to create multiple decks and cards, and use them as flashcards.  Users can edit or delete any decks or cards.  When studying the flashcards, users can click the card to flip it back and forth.
          </h4>
          <div className="technologies">
          <h5>Built with:</h5>
          </div>
          <div className="project-description">
            <ul className="project-skills">
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>ReactJS</li>
              <li>Bootstrap</li>
            </ul>
          </div>
          <div className="portfolio__item-CTA">
            <a href="https://github.com/christianDCdev/flashcard-react-app" target="__blank" className="btn">Code</a>
            <a className="btn btn-secondary">Demo Soon</a>
          </div>
        </article>
      </div>
      </section>
  )
}

export default Projects